// This is for heck is the page is the last page of the research or note (this is for the progress bar)
if (document.querySelector('.research-page')) {
    const prevBtns = document.querySelectorAll(".previous");
    const nextBtns = document.querySelectorAll(".next");
    const formSteps = document.querySelectorAll(".step-form");
    const circleProgressBar = document.querySelectorAll(".circle")
    const progressMeter = document.querySelector(".progress-meter");
    let formStepsNum = 0;
    const formStepsMax = formSteps.length;
    const searchBtn = document.querySelector(".search");

    searchBtn.style.display = 'none';


    updateFormSteps();

    // This is for display or hide the next/previous button, depending of the current step 
    function updateButtonsVisibility() {
        prevBtns.forEach(btn => btn.classList.remove("hidden"));
        nextBtns.forEach(btn => btn.style.display = 'none');

        if (formStepsNum === 0) {
            prevBtns.forEach(btn => btn.classList.add("hidden"));
        } else if (formStepsNum >= (formStepsMax - 1)) {
            nextBtns.forEach(btn => btn.style.display = 'none');
        }
    }
    // This is for update the progress bar and display the correct step
    function updateFormSteps() {
        formSteps.forEach((formStep) => {
            formStep.classList.remove("show");
        });
        circleProgressBar.forEach((circle) => {
            circle.classList.remove("current", "past");
        });

        const widthPercentage = (100 / (circleProgressBar.length - 1)) * (formStepsNum);
        progressMeter.style.width = widthPercentage + "%";
        formSteps[formStepsNum].classList.add("show");
        circleProgressBar[formStepsNum].classList.add("current");

        for (let i = 0; i < formStepsNum; i++) {
            circleProgressBar[i].classList.add("past");
        }
        // Updated the next/previous buttons
        updateButtonsVisibility();
    }
    // For each click on the next button (update the progress bar/step/buttons)
    nextBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
            if (formStepsNum < formStepsMax - 1) {
                formStepsNum++;
                window.scrollTo({ top: 0, behavior: 'smooth' });
                updateFormSteps();
            }
        });
    });
    // For each click on the previous button (update the progress bar/step/buttons))
    prevBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
            if (formStepsNum > 0) {
                formStepsNum--;
                updateFormSteps();
                window.scrollTo({ top: 0, behavior: 'smooth' });
                nextBtns.forEach(btn => btn.style.display = 'inline-block');
                searchBtn.style.display = 'none';
            }
        });
    });

    const categoryBtns = document.querySelectorAll('.icon-button');
    const subcategorySection = document.getElementById('subcategories');
    const subcategoryGroups = document.querySelectorAll('.subcategory-group');
    const subcategoryBtns = document.querySelectorAll('.selection-btn');
    const hiddenSubcategoryInput = document.getElementById('find_technician_subcategory');

    // For each category button click
    categoryBtns.forEach(btn => {
        btn.addEventListener('click', () => {
            // Unselect all category buttons and select the current category button
            categoryBtns.forEach(b => b.classList.remove('selected'));
            btn.classList.add('selected');

            // Unselect all subcategory buttons
            subcategoryBtns.forEach(b1 => b1.classList.remove('selected'));

            // Reset the input and hide the next button
            hiddenSubcategoryInput.value = '';
            nextBtns.forEach(b2 => b2.style.display = 'none');

            // Hide all the subcategory divs
            subcategoryGroups.forEach(group => group.classList.add('hidden'));
            // Get the category chosen
            const categoryGroup = btn.getAttribute('data-category');
            // Display the subcategory div associated to the current category
            document.querySelector(`.subcategory-group[data-category-group="${categoryGroup}"]`).classList.remove('hidden');

            // Scroll until the subcategory div
            const yOffset = -7 * parseFloat(getComputedStyle(document.documentElement).fontSize);
            const y = subcategorySection.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        });
    });

    // For each subcategory button click
    subcategoryBtns.forEach(btn => {
        btn.addEventListener('click', () => {
            // Unselect all subcategory buttons and select the correct button
            subcategoryBtns.forEach(b => b.classList.remove('selected'));
            btn.classList.add('selected');

            // Put the data in the hidden input and display the next button
            hiddenSubcategoryInput.value = btn.getAttribute('data-subcategory');
            nextBtns.forEach(btn => btn.style.display = 'inline-block');
        });
    });

    // This is for display the progress bar completed at 100%
} else if (document.querySelector('.result-page')) {
    const circleProgressBar = document.querySelectorAll(".circle")
    const progressMeter = document.querySelector(".progress-meter");
    progressMeter.style.width = "100%"

    for (let i = 0; i < circleProgressBar.length; i++) {
        circleProgressBar[i].classList.remove("current");
        circleProgressBar[i].classList.add("past");

    }
    circleProgressBar[circleProgressBar.length - 1].classList.add("current");
}